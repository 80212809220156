import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import LanguageIcon from '@material-ui/icons/Language';

import { LinksGridList } from './EntityLinksCard/LinksGridList';
import { MissingLink } from './MissingLink';
import { ColumnBreakpoints } from './EntityLinksCard/columnBreakpoints';

export interface FilteredEntityLinksCardProps {
  cols?: ColumnBreakpoints | number;
  variant?: InfoCardVariants;
  removeByType?: string[];
}

export const FilteredEntityLinksCard = (
  props: FilteredEntityLinksCardProps,
) => {
  const { cols = undefined } = props;
  const { entity } = useEntity();
  const app = useApp();

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  const links = entity?.metadata?.links?.filter(
    link => !props.removeByType?.includes(link.type ?? ''),
  );

  return (
    <InfoCard title="Links" variant="fullHeight">
      {!links || links.length === 0 ? (
        <MissingLink
          links={[{ url: 'https://example.com', title: 'Example Link' }]}
          entity={entity}
        />
      ) : (
        <LinksGridList
          cols={cols}
          items={links.map(({ url, title, icon }) => ({
            text: title ?? url,
            href: url,
            Icon: iconResolver(icon),
          }))}
        />
      )}
    </InfoCard>
  );
};
