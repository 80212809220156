import { createPermission } from '@backstage/plugin-permission-common';

/**
 * @public
 */
export const frontendTokenPermission = createPermission({
  name: 'frontend.tokenretrieval',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const frontendGraphiqlPermission = createPermission({
  name: 'frontend.graphiql',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const frontendVersionDashboardPermission = createPermission({
  name: 'frontend.versiondashboard',
  attributes: { action: 'read' },
});

/**
 * List of all Frontend permissions
 *
 * @public
 */
export const frontEndPermissions = [
  frontendTokenPermission,
  frontendGraphiqlPermission,
  frontendVersionDashboardPermission,
];
