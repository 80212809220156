import React from 'react';

import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  DefaultEntityFilters,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

import type {
  CriticalityType,
  ExposureType,
  DataPropertiesType,
} from '@zeiss/backstage-plugin-security-asset-classification-common';

export type CustomFilters = DefaultEntityFilters & {
  securityCriticalities?: EntitySecurityCriticalityFilter;
  exposures?: EntityExposureFilter;
  dataProperties?: EntityDataPropertiesFilter;
};

class EntitySecurityCriticalityFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const criticality =
      // @ts-ignore
      entity.spec?.zeiss?.security?.classification.criticality;
    return criticality !== undefined && this.values.includes(criticality);
  }
}

class EntityExposureFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const exposure =
      // @ts-ignore
      entity.spec?.zeiss?.security?.classification.exposure;
    return exposure !== undefined && this.values.includes(exposure);
  }
}

class EntityDataPropertiesFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const dataProperties =
      // @ts-ignore
      entity.spec?.zeiss?.security?.classification?.['data-properties'];
    const dataPropertiesArray = dataProperties?.split('|');
    return (
      dataPropertiesArray !== undefined &&
      this.values.some(v => dataPropertiesArray.includes(v))
    );
  }
}

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .11)',
      boxShadow: 'none',
      margin: theme.spacing(1, 0, 1, 0),
    },
    title: {
      margin: theme.spacing(1, 0, 0, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 'bold',
    },
    listIcon: {
      minWidth: 30,
      color: theme.palette.text.primary,
    },
    menuItem: {
      minHeight: theme.spacing(6),
    },
    groupWrapper: {
      margin: theme.spacing(1, 1, 0, 1),
    },
  }),
  {
    name: 'CatalogReactUserListPicker',
  },
);

export const SecurityClassificationFilter = () => {
  const {
    filters: { securityCriticalities, exposures, dataProperties },
    updateFilters,
  } = useEntityList<CustomFilters>();

  const classes = useStyles();

  function onCriticalityChange(value: string) {
    const newCriticalities = securityCriticalities?.values.includes(value)
      ? securityCriticalities.values.filter(crit => crit !== value)
      : [...(securityCriticalities?.values ?? []), value];
    updateFilters({
      securityCriticalities: newCriticalities.length
        ? new EntitySecurityCriticalityFilter(newCriticalities)
        : undefined,
    });
  }

  function onExposureChange(value: string) {
    const newExposures = exposures?.values.includes(value)
      ? exposures.values.filter(exp => exp !== value)
      : [...(exposures?.values ?? []), value];
    updateFilters({
      exposures: newExposures.length
        ? new EntityExposureFilter(newExposures)
        : undefined,
    });
  }

  function onDataPropertiesChange(value: string) {
    const newDataProperties = dataProperties?.values.includes(value)
      ? dataProperties.values.filter(dp => dp !== value)
      : [...(dataProperties?.values ?? []), value];
    updateFilters({
      dataProperties: newDataProperties.length
        ? new EntityDataPropertiesFilter(newDataProperties)
        : undefined,
    });
  }

  const securityCriticalityOptions: CriticalityType[] = [
    'Low',
    'Medium',
    'High',
    'Critical',
  ];

  const exposureOptions: ExposureType[] = [
    'Public Internet',
    'Global ZEISS Network',
    'Local Subnet',
    'Local access only',
  ];

  // stored under entity.spec?.zeiss?.security?.classification?.['data-properties'] as string. Seperated by "|", e.g. "PII|Sensitive PII"
  const dataPropertiesOptions: DataPropertiesType[] = [
    'PII',
    'Sensitive PII',
    'PHI',
    'Continuity-critical',
    'Service-Continuity-critical',
    'Platform-Continuity-critical',
    'Data-Recovery-critical',
  ];

  return (
    <Card className={classes.root}>
      <FormControl component="fieldset">
        <Typography
          variant="subtitle2"
          component="span"
          className={classes.title}
        >
          Security Criticality
        </Typography>
        <FormGroup className={classes.groupWrapper}>
          {securityCriticalityOptions.map(criticality => (
            <FormControlLabel
              key={criticality}
              control={
                <Checkbox
                  checked={securityCriticalities?.values.includes(criticality)}
                  onChange={() => onCriticalityChange(criticality)}
                />
              }
              label={criticality}
            />
          ))}
        </FormGroup>
        <Typography
          variant="subtitle2"
          component="span"
          className={classes.title}
        >
          Exposure
        </Typography>
        <FormGroup className={classes.groupWrapper}>
          {exposureOptions.map(exposure => (
            <FormControlLabel
              key={exposure}
              control={
                <Checkbox
                  checked={exposures?.values.includes(exposure)}
                  onChange={() => onExposureChange(exposure)}
                />
              }
              label={exposure}
            />
          ))}
        </FormGroup>
        <Typography
          variant="subtitle2"
          component="span"
          className={classes.title}
        >
          Data Properties
        </Typography>
        <FormGroup className={classes.groupWrapper}>
          {dataPropertiesOptions.map(dataProperty => (
            <FormControlLabel
              key={dataProperty}
              control={
                <Checkbox
                  checked={dataProperties?.values.includes(dataProperty)}
                  onChange={() => onDataPropertiesChange(dataProperty)}
                />
              }
              label={dataProperty}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Card>
  );
};
