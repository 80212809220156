import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const entityLinterPlugin = createPlugin({
  id: 'entity-linter',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityLinterPage = entityLinterPlugin.provide(
  createRoutableExtension({
    name: 'EntityLinterPage',
    component: () =>
      import('./components/EntityLinterPage').then(m => m.EntityLinterPage),
    mountPoint: rootRouteRef,
  }),
);
