import { AdrContentDecorator } from '@backstage/plugin-adr';

export const adrImageRewriteDecorator: AdrContentDecorator = ({ content }) => {
  const backend_url =
    window.location.origin === 'http://localhost:3000'
      ? 'http://localhost:7007'
      : window.location.origin;

  const imageRegex = /!\[(.*?)\]\((.*?)\)/g;

  const updatedContent = content.replace(imageRegex, (match, altText, url) => {
    const urlRegex = new RegExp(
      /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/,
    );
    if (urlRegex.test(url)) {
      const urlObj = new URL(url);
      const path = decodeURIComponent(urlObj.searchParams.get('path') || '');
      if (path.includes('..')) {
        const pathParts = path.split('/');
        for (let i = 0; i < pathParts.length; i++) {
          if (pathParts[i] === '..') {
            pathParts.splice(i - 1, 2);
            i -= 2;
          }
        }
        urlObj.searchParams.set('path', pathParts.join('/'));
        const newUrl = urlObj
          .toString()
          .replace(urlObj.search, decodeURIComponent(urlObj.search));

        const proxyUrl = `${backend_url}/api/dynamic-repo-proxy?url=${encodeURIComponent(
          newUrl,
        )}`;
        return `![${altText}](${proxyUrl})`;
      }
    }
    return match;
  });

  return { content: updatedContent };
};
