import { TechRadarLoaderResponse } from '@backstage/plugin-tech-radar';

interface CopRing {
  id: string;
  name: string;
  color: string;
}

interface CopQuadrant {
  id: string;
  name: string;
}

interface CopTimeline {
  moved: number;
  ringId: string;
  date: string;
  description?: string;
}

interface CopEntry {
  id: string;
  key: string;
  title: string;
  description: string;
  url: string;
  quadrant: string;
  timeline: CopTimeline[];
}

interface CopRadarResponse {
  rings: CopRing[];
  quadrants: CopQuadrant[];
  entries: CopEntry[];
}

export const handle = (data: CopRadarResponse): TechRadarLoaderResponse => {
  const quadrants = data.quadrants.map(quadrant => {
    return {
      id: quadrant.id,
      name: quadrant.name,
    };
  });

  const rings = data.rings.map(ring => {
    return {
      id: ring.name.toLowerCase(),
      name: ring.name,
      color: ring.color,
    };
  });

  const entries = data.entries.map(entity => {
    return {
      timeline: entity.timeline.map(timeline => {
        return {
          ringId: timeline.ringId.toLowerCase(),
          date: new Date(timeline.date),
          description: timeline.description,
        };
      }),
      url: entity.url,
      key: entity.key,
      id: entity.id,
      title: entity.title,
      quadrant: entity.quadrant,
      description: entity.description,
    };
  });

  return {
    quadrants: quadrants,
    rings: rings,
    entries: entries,
  };
};
