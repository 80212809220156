import React, { ReactNode } from 'react';
import { FeatureFlagged } from '@backstage/core-app-api';

import { useIsZeissFeatureFlagged } from '../../hooks/isZeissFeatureFlagged/isZeissFeatureFlagged';

export const ZeissFeatureFlagged = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  const active = useIsZeissFeatureFlagged(name);

  return (
    <>
      {active ? <FeatureFlagged with={name}>{children}</FeatureFlagged> : null}
    </>
  );
};
