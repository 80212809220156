import React, { useEffect, useState } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

import { Link } from '@backstage/core-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  Box,
  Grid,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { checkRecommendations } from '@zeiss/backstage-plugin-recommendations-common';
import type { Recommendation } from '@zeiss/backstage-plugin-recommendations-common';

import { generateSearchQueryRepoUrlPicker } from '../../utils';

export const RecommendationsCard = (props: {
  recommendations?: Recommendation[];
}) => {
  const [recommendations, setRecommendations] = useState<Recommendation[]>(
    props.recommendations || [],
  );
  const { entity } = useEntity();

  useEffect(() => {
    if (!props.recommendations && entity) {
      const newRecommendations = checkRecommendations(entity);
      setRecommendations(newRecommendations);
    } else {
      setRecommendations(props.recommendations || []);
    }
  }, [props.recommendations, entity]);

  return (
    <Card>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <InfoIcon color="secondary" />
            <Typography
              variant="h6"
              style={{ paddingLeft: 8 }}
              color="secondary"
              align="center"
            >
              Recommendations
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {recommendations.map((recommendation, index) => (
              <Grid item key={index} xs={12} md={6}>
                {/* padding: */}
                <Card
                  style={{
                    height: '100%',
                    padding: 16,
                  }}
                  elevation={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box mb={2}>
                      <Typography variant="h6">
                        {recommendation.title}
                      </Typography>
                      <Typography>{recommendation.description}</Typography>
                    </Box>
                    {recommendation.links.map(link => (
                      <Box key={index} mb={1}>
                        <Link
                          to={
                            entity &&
                            link.searchQueryGenerator?.type === 'RepoUrlPicker'
                              ? link.url +
                                generateSearchQueryRepoUrlPicker(
                                  entity.metadata.annotations?.[
                                    'backstage.io/source-location'
                                  ],
                                )
                              : link.url
                          }
                        >
                          <Button
                            color="primary"
                            href={
                              link.url.startsWith('http') ? link.url : undefined
                            }
                            onClick={() => {}}
                            variant={link.variant}
                            fullWidth
                          >
                            {link.title}{' '}
                          </Button>
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
