import { createComponentExtension } from '@backstage/core-plugin-api';
import { homePlugin } from '@backstage/plugin-home'; // Import ComponentParts
import { ComponentType } from 'react';

export const QuickActionBar: ComponentType = homePlugin.provide(
  createComponentExtension({
    name: 'QuickActionBar',
    component: {
      lazy: () =>
        import('./components/QuickActionBar').then(m => m.QuickActionBar),
    },
  }),
);

export const Toolkit: ComponentType = homePlugin.provide(
  createComponentExtension({
    name: 'Toolkit',
    component: {
      lazy: () => import('./components/Toolkit').then(m => m.Toolkit),
    },
  }),
);
