import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 350,
    height: 300,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width="100" height="100" viewBox="0 0 240 50">
    <path d="M48 48C32.7336 41.1482 15.2664 41.1482 0 48V0H48V48Z" fill="#0F2DB3"/>
    <path d="M20.3609 20.6003V23.9963L11.5119 23.9888V22.8817H11.7663C11.9886 22.8821 12.2019 22.7939 12.3591 22.6368C12.5163 22.4796 12.6044 22.2663 12.604 22.044V15.4914C12.6044 15.2691 12.5163 15.0558 12.3591 14.8986C12.2019 14.7414 11.9886 14.6532 11.7663 14.6536H11.5119V13.539H20.3609V16.935H19.2389C19.2389 16.935 19.0295 14.6461 17.0772 14.6461H15.9551V18.2066H16.3516C16.5762 18.2082 16.7921 18.1196 16.9509 17.9607C17.1098 17.8019 17.1983 17.586 17.1968 17.3614V16.935H18.3039V20.5928H17.1968V20.1739C17.1983 19.9493 17.1098 19.7334 16.9509 19.5746C16.7921 19.4157 16.5762 19.3272 16.3516 19.3287H15.9551V22.8892H17.0772C19.0295 22.8892 19.2389 20.6003 19.2389 20.6003H20.3609Z" fill="white"/>
    <path d="M5.6924 14.6461C4.40596 14.6537 3.34282 15.6516 3.25388 16.935H2.17674V13.5465H10.5769C10.5769 13.5465 10.6442 16.1646 8.16084 18.9397C8.16084 18.9397 5.79712 21.6849 5.73728 22.8892H7.09866C9.05098 22.8892 9.25294 20.6003 9.25294 20.6003H10.375V23.9963H1.78777C1.78777 23.9963 1.4736 21.4231 4.4507 18.3039C4.4507 18.3039 6.28333 16.2543 6.7097 14.6386L5.6924 14.6461Z" fill="white"/>
    <path d="M27.0856 23.9888V22.8817H26.8313C26.6089 22.8821 26.3956 22.7939 26.2384 22.6367C26.0813 22.4795 25.9931 22.2662 25.9935 22.0439V15.4913C25.9931 15.269 26.0813 15.0557 26.2384 14.8985C26.3956 14.7414 26.6089 14.6532 26.8313 14.6536H27.0856V13.5465H21.5129V14.6536H21.7672C21.9907 14.6516 22.2056 14.7391 22.3644 14.8964C22.5231 15.0537 22.6124 15.2679 22.6125 15.4913V22.0439C22.6124 22.2674 22.5231 22.4816 22.3644 22.6389C22.2056 22.7962 21.9907 22.8836 21.7672 22.8817H21.5129V23.9888H27.0856Z" fill="white"/>
    <path d="M32.935 14.6461C34.2219 14.6493 35.2854 15.6506 35.3661 16.935H36.4507V13.5465H28.4245C28.4245 13.5465 27.1529 15.2071 30.474 18.9397C30.474 18.9397 32.8303 21.6849 32.8901 22.8892H31.5362C29.5839 22.8892 29.3745 20.6003 29.3745 20.6003H28.245V23.9963H36.3385C36.3385 23.9963 37.8121 22.4105 34.1692 18.3039C34.1692 18.3039 32.2618 16.3441 31.9102 14.6386L32.935 14.6461Z" fill="white"/>
    <path d="M44.7536 16.935C44.6695 15.6523 43.608 14.6529 42.3226 14.6461L41.3127 14.6386C41.6568 16.3441 43.5643 18.3039 43.5643 18.3039C47.1996 22.4105 45.7335 23.9963 45.7335 23.9963H37.64V20.6003H38.762C38.762 20.6003 38.9715 22.8892 40.9238 22.8892H42.2777C42.2178 21.6849 39.8616 18.9397 39.8616 18.9397C36.5404 15.2071 37.812 13.5465 37.812 13.5465H45.8307V16.935H44.7536Z" fill="white"/>
   </svg>
  );
};

export default LogoFull;
