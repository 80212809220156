import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';

import { InfoCard, Link } from '@backstage/core-components';
import { Button, Chip, Grid, Typography } from '@material-ui/core';

import type {
  CriticalityType,
  DataPropertiesType,
  InformationCategoriesType,
  SecurityClassificationType,
  ExposureType,
  Entity,
} from '@zeiss/backstage-plugin-security-asset-classification-common';
import { generateSearchQueryRepoUrlPicker } from '@zeiss/backstage-plugin-recommendations';

type SecurityAssetClassificationType = {
  criticality?: CriticalityType;
  dataProperties?: DataPropertiesType[];
  informationCategories?: InformationCategoriesType[];
  securityClassification?: SecurityClassificationType[];
  exposure?: ExposureType;
};

export const isSecurityAssetClassificationAvailable = (entity: Entity) =>
  Boolean(
    (entity.kind === 'System' ||
      entity.kind === 'API' ||
      entity.kind === 'Component') &&
      entity.spec?.type !== 'documentation' &&
      (entity.spec?.zeiss?.security?.classification?.criticality as string),
  );
export const useSecurityAssetClassifications = ({
  entity,
}: {
  entity: Entity;
}): SecurityAssetClassificationType => {
  return {
    criticality: entity.spec?.zeiss?.security?.classification.criticality,
    dataProperties: entity.spec?.zeiss?.security?.classification[
      'data-properties'
    ].split('|') as DataPropertiesType[],
    informationCategories: entity.spec?.zeiss?.security?.classification[
      'information-categories'
    ].split('|') as InformationCategoriesType[],
    securityClassification: entity.spec?.zeiss?.security?.classification[
      'security-classification'
    ].split('|') as SecurityClassificationType[],
    exposure: entity.spec?.zeiss?.security?.classification.exposure,
  };
};

const determineCriticalityColor = (criticality: CriticalityType) => {
  switch (criticality) {
    case 'Low':
      return '##ffff54';
    case 'Medium':
      return '#f5c242';
    case 'High':
      return '#ea3423';
    default:
      return '#b02518';
  }
};

const ClassificationField = ({
  label,
  values,
}: {
  label: string;
  values?: string[] | string;
}) => {
  if (typeof values === 'string') {
    return (
      <Grid item xs={6}>
        <Typography variant="h6">{label}</Typography>
        <Chip
          label={values}
          size={label === 'Criticality' ? 'medium' : 'small'}
          style={
            label === 'Criticality'
              ? {
                  backgroundColor: determineCriticalityColor(
                    values as CriticalityType,
                  ),
                  fontWeight: 'bold',
                }
              : undefined
          }
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={6}>
      <Typography variant="h6">{label}</Typography>
      {values?.map(value => (
        <Chip label={value} size="small" />
      ))}
    </Grid>
  );
};

export const SecurityAssetClassification = () => {
  const { entity } = useEntity();

  const {
    criticality,
    dataProperties,
    informationCategories,
    securityClassification,
    exposure,
  } = useSecurityAssetClassifications({ entity: entity as Entity });

  if (!criticality) {
    return (
      <InfoCard title="Security Asset Classification" variant="fullHeight">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Missing classification</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please classify this component with the security asset
              classification template. You can use the following button to
              create a classification.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/create/templates/default/v1${
                entity.kind === 'system' ? 'application' : 'component'
              }-security-asset-classification${generateSearchQueryRepoUrlPicker(
                entity.metadata.annotations?.['backstage.io/source-location'],
              )}`}
            >
              <Button
                color="primary"
                href="#"
                onClick={() => {}}
                variant="contained"
              >
                CREATE Classification
              </Button>
            </Link>
          </Grid>
        </Grid>
      </InfoCard>
    );
  }

  return (
    <InfoCard title="Security Asset Classification" variant="fullHeight">
      <Grid container spacing={3}>
        <ClassificationField label="Criticality" values={criticality} />
        <ClassificationField label="Data Properties" values={dataProperties} />
        <ClassificationField
          label="Information Categories"
          values={informationCategories}
        />
        <ClassificationField
          label="Security Classification"
          values={securityClassification}
        />
        <ClassificationField label="Exposure" values={exposure} />
      </Grid>
    </InfoCard>
  );
};
