import React from 'react';

import { Entity } from '@backstage/catalog-model';
import { CodeSnippet } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { Typography, Box, makeStyles } from '@material-ui/core';

type MissingAnnotationType = {
  annotations: string[];
  entity: Entity;
  description?: string;
};

const useStyles = makeStyles<BackstageTheme>(theme => ({
  code: {
    borderRadius: 6,
    margin: theme.spacing(2, 0),
    background:
      theme.palette.type === 'dark' ? '#444' : theme.palette.common.white,
  },
}));

function generateYamlExampleAnnotation(
  annotations: string[],
  entity?: Entity,
): { yamlText: string; lineNumbers: number[] } {
  const kind = entity?.kind || 'Component';
  const name = entity?.metadata.name || 'example';
  const type = entity?.spec?.type || 'website';
  const owner = entity?.spec?.owner || 'user:default/guest';

  const yamlText = `apiVersion: backstage.io/v1alpha1
kind: ${kind}
metadata:
  name: ${name}
  annotations:${annotations.map(ann => `\n    ${ann}: value`).join('')}
spec:
  type: ${type}
  owner: ${owner}`;

  let line = 6; // Line 6 is the line number that annotations are added to.
  const lineNumbers: number[] = [];
  annotations.forEach(() => {
    lineNumbers.push(line);
    line++;
  });

  return {
    yamlText,
    lineNumbers,
  };
}

export const MissingAnnotation = ({
  annotations,
  entity,
  description,
}: MissingAnnotationType) => {
  const classes = useStyles();
  const { yamlText, lineNumbers } = generateYamlExampleAnnotation(
    annotations,
    entity,
  );

  return (
    <>
      <Typography variant="body1">
        {description ||
          'Add the annotation to your catalog-info.yaml as shown in the highlighted example below:'}
      </Typography>
      <Box className={classes.code}>
        <CodeSnippet
          text={yamlText}
          language="yaml"
          showLineNumbers
          highlightedNumbers={lineNumbers}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
      </Box>
    </>
  );
};
