import { InfoCard } from '@backstage/core-components';
import { AboutField } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

type ValueDisplayCardProps = {
  title: string;
  values: Record<string, string | object>;
};

export const ValueDisplayCard = ({ title, values }: ValueDisplayCardProps) => (
  <InfoCard title={title} variant="fullHeight">
    <Grid container spacing={3}>
      {Object.entries(values).map(([key, value]) => (
        <Grid item xs={6} md={4} key={key}>
          <AboutField
            label={key}
            value={
              typeof value === 'object'
                ? JSON.stringify(value, null, 2)
                : String(value)
            }
          />
        </Grid>
      ))}
    </Grid>
  </InfoCard>
);
