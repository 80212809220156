import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  configApiRef,
} from '@backstage/core-plugin-api';
import { TechRadarLoader } from './TechRadarLoader';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';

import { rootRouteRef } from './routes';

export const zeissTechRadarPlugin = createPlugin({
  id: 'zeiss-tech-radar',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: techRadarApiRef,
      deps: {
        configApi: configApiRef,
      },
      factory: ({ configApi }) => new TechRadarLoader({ configApi }),
    }),
  ],
});

export const ZeissTechRadarPage = zeissTechRadarPlugin.provide(
  createRoutableExtension({
    name: 'ZeissTechRadarPage',
    component: () => import('./pages/TechRadarPage').then(m => m.TechRadarPage),
    mountPoint: rootRouteRef,
  }),
);
