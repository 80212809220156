import React from 'react';

import { Entity, EntityLink } from '@backstage/catalog-model';
import { EntityRefLinks, useEntity } from '@backstage/plugin-catalog-react';
import {
  HeaderIconLinkRow,
  InfoCard,
  IconLinkVerticalProps,
} from '@backstage/core-components';

import { Typography, Link, Box, Grid } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import SubjectIcon from '@material-ui/icons/Subject';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HttpsIcon from '@material-ui/icons/Https';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { MissingLink } from './MissingLink';

type ObservabilityTelemetryType = {
  dashboardWorkspace?: EntityLink;
  tracingWorkspace?: EntityLink;
  loggingWorkspace?: EntityLink;
  incidentPlaybooks?: EntityLink;
  tykApiWorkspace?: EntityLink;
  itsmTicketCreate?: EntityLink;
  incidentContactMail?: EntityLink;
};

type ExtendedEntity = Entity & {
  spec: {
    type: string;
    zeiss?: {
      observability?: {
        componentowner?: string;
      };
    };
  };
};

export const isObservabilityTelemetryAvailable = (entity: Entity) =>
  Boolean(
    entity.kind === 'Component' &&
      entity?.spec?.type === 'service' &&
      entity.metadata.links?.some(
        link =>
          link.type === 'dashboard-workspace' ||
          link.type === 'tracing-workspace' ||
          link.type === 'logging-workspace' ||
          link.type === 'incident-playbooks' ||
          link.type === 'tyk-api-workspace' ||
          link.type === 'itsm-ticket-create' ||
          link.type === 'incident-contact-mail',
      ),
  );

export const useObservabilityTelemetry = ({
  entity,
}: {
  entity: Entity;
}): ObservabilityTelemetryType => {
  return {
    dashboardWorkspace: entity.metadata.links?.find(
      link => link.type === 'dashboard-workspace',
    ),
    tracingWorkspace: entity.metadata.links?.find(
      link => link.type === 'tracing-workspace',
    ),
    loggingWorkspace: entity.metadata.links?.find(
      link => link.type === 'logging-workspace',
    ),
    incidentPlaybooks: entity.metadata.links?.find(
      link => link.type === 'incident-playbooks',
    ),
    tykApiWorkspace: entity.metadata.links?.find(
      link => link.type === 'tyk-api-workspace',
    ),
    itsmTicketCreate: entity.metadata.links?.find(
      link => link.type === 'itsm-ticket-create',
    ),
    incidentContactMail: entity.metadata.links?.find(
      link => link.type === 'incident-contact-mail',
    ),
  };
};

export const ObservabilityTelemetryCard = () => {
  const { entity } = useEntity() as { entity: ExtendedEntity };

  const {
    dashboardWorkspace,
    tracingWorkspace,
    loggingWorkspace,
    incidentPlaybooks,
    tykApiWorkspace,
    itsmTicketCreate,
    incidentContactMail,
  } = useObservabilityTelemetry({ entity });

  if (entity?.spec?.type === 'service') {
    if (
      !dashboardWorkspace &&
      !tracingWorkspace &&
      !loggingWorkspace &&
      !incidentPlaybooks &&
      !tykApiWorkspace &&
      !itsmTicketCreate &&
      !incidentContactMail
    ) {
      return (
        <InfoCard title="Observability & Telemetry" variant="fullHeight">
          <MissingLink
            links={[
              { type: 'dashboard-workspace' },
              { type: 'tracing-workspace' },
              { type: 'logging-workspace' },
              { type: 'incident-playbooks' },
              { type: 'tyk-api-workspace' },
              { type: 'itsm-ticket-create' },
              { url: 'mailto:example@mail.com', type: 'incident-contact-mail' },
            ]}
            entity={entity}
          />
        </InfoCard>
      );
    }

    const links = [
      {
        label: 'Dashboard',
        disabled: !dashboardWorkspace?.url,
        href: dashboardWorkspace?.url ?? '',
        icon: <DashboardIcon />,
      },
      {
        label: 'Tracing',
        disabled: !tracingWorkspace?.url,
        href: tracingWorkspace?.url ?? '',
        icon: <GraphicEqIcon />,
      },
      {
        label: 'Logging',
        disabled: !loggingWorkspace?.url,
        href: loggingWorkspace?.url ?? '',
        icon: <SubjectIcon />,
      },
      {
        label: 'Incident Playbooks',
        disabled: !incidentPlaybooks?.url,
        href: incidentPlaybooks?.url ?? '',
        icon: <MenuBookIcon />,
      },
      tykApiWorkspace?.url && {
        label: 'Tyk API',
        disabled: !tykApiWorkspace?.url,
        href: tykApiWorkspace?.url ?? '',
        icon: <HttpsIcon />,
      },
      {
        label: 'ITSM Ticket',
        disabled: !itsmTicketCreate?.url,
        href: itsmTicketCreate?.url ?? '',
        icon: <PlaylistAddIcon />,
      },
    ].filter(Boolean) as IconLinkVerticalProps[];

    const componentOwner = entity.spec.zeiss?.observability?.componentowner;

    return (
      <InfoCard title="Observability & Telemetry" variant="fullHeight">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <HeaderIconLinkRow links={links} />
        </Box>
        <Grid container spacing={1}>
          {componentOwner && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Component-Owner</Typography>
              <EntityRefLinks
                entityRefs={[componentOwner]}
                defaultKind="User"
              />
            </Grid>
          )}
          {incidentContactMail && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">SRE-Operations-Owner</Typography>
              <Link href={incidentContactMail.url} color="primary">
                <Typography variant="body1">
                  {incidentContactMail.url.replace(/^mailto:/, '')}
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </InfoCard>
    );
  }
  return null;
};
