import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const versionDashboardPlugin = createPlugin({
  id: 'version-dashboard',
  routes: {
    root: rootRouteRef,
  },
});

export const VersionDashboardPage = versionDashboardPlugin.provide(
  createRoutableExtension({
    name: 'VersionDashboardPage',
    component: () =>
      import('./pages/VersionDashboardPage').then(m => m.VersionDashboardPage),
    mountPoint: rootRouteRef,
  }),
);
