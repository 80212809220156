import { Entity } from '@backstage/catalog-model';
import { checkRecommendations } from '@zeiss/backstage-plugin-recommendations-common';

export const isRecommendationAvailable = (entity: Entity): boolean => {
  const recommendations = checkRecommendations(entity);
  return recommendations.length > 0;
};

export const generateSearchQueryRepoUrlPicker = (
  sourceLocation?: string,
  propertyName = 'repoUrl',
) => {
  if (!sourceLocation) {
    return '';
  }

  const url = new URL(sourceLocation.substring(4)); // Remove "url:" prefix
  if (url.hostname === 'dev.azure.com') {
    const parts = url.pathname.split('/');
    const organization = parts[1];
    const owner = parts[2];
    const repo = parts[4];
    const repoUrl = `${url.hostname}?owner=${owner}&repo=${repo}&organization=${organization}`;
    return `?formData={"${propertyName}"%3A"${encodeURIComponent(repoUrl)}"}`;
  }

  return '';
};
