import React from 'react';

import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Header, Page } from '@backstage/core-components';
import {
  WelcomeTitle,
  HomePageStarredEntities,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HeaderWorldClock,
  CustomHomepageGrid,
  ClockConfig,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { QuestionTableCard } from '@drodil/backstage-plugin-qeta';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import { QuickActionBar, Toolkit } from '@zeiss/backstage-plugin-home-react';

const clockConfigs: ClockConfig[] = [
  {
    label: 'GER',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'HUN',
    timeZone: 'Europe/Budapest',
  },
  {
    label: 'IST',
    timeZone: 'Asia/Kolkata',
  },
  {
    label: 'CST',
    timeZone: 'Asia/Shanghai',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const defaultConfig = [
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 0,
    width: 6,
    height: 2,
  },
  {
    component: 'QuickActionBar',
    x: 7,
    y: 0,
    width: 6,
    height: 2,
  },
  {
    component: 'HomePageStarredEntities',
    x: 0,
    y: 1,
    width: 3,
    height: 7,
  },
  {
    component: 'AnnouncementsCard',
    x: 3,
    y: 1,
    width: 3,
    height: 7,
  },
  {
    component: 'QuestionsTableCard',
    x: 6,
    y: 1,
    width: 6,
    height: 8,
  },
  {
    component: 'HomePageTopVisited',
    x: 0,
    y: 2,
    width: 3,
    height: 7,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 3,
    y: 2,
    width: 3,
    height: 7,
  },
  {
    component: 'Toolkit',
    x: 6,
    y: 2,
    width: 6,
    height: 6,
  },
];

export const HomePage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header
          title="ZEISS Digital Service Catalog"
          subtitle={<WelcomeTitle language={['English']} />}
        >
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <CustomHomepageGrid config={defaultConfig}>
            <HomePageSearchBar />
            <QuickActionBar />
            <HomePageStarredEntities />
            <AnnouncementsCard />
            <QuestionTableCard />
            <HomePageTopVisited />
            <HomePageRecentlyVisited />
            <Toolkit />
          </CustomHomepageGrid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
