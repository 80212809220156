import Ajv from 'ajv';
import {
  Check,
  Parameter,
  RecommendationSchema,
  Recommendation,
} from './index';
import schema from './schemas/zeiss-entity-recommendation-schema.json';
import recommendationsconfig from './recommendations.json';

const ajv = new Ajv();

export const getValue = (entity: any, path: string): any => {
  const keys =
    path
      .match(/("[^"]*"|'[^']*'|\w+)/g)
      ?.map(key => key.replace(/['"]/g, '')) || [];
  let value = entity;
  for (const key of keys) {
    if (key in value) {
      value = value[key];
    } else {
      return null;
    }
  }
  return value;
};

const checkParameter = (entity: any, parameter: Parameter): boolean => {
  const value = getValue(entity, parameter.path);

  if ('exists' in parameter) {
    return (value !== null) === parameter.exists;
  } else if ('value' in parameter) {
    return value === parameter.value;
  } else if ('notValue' in parameter) {
    return value !== parameter.notValue;
  } else if ('valuesInArray' in parameter) {
    if (Array.isArray(value)) {
      return parameter.valuesInArray!.every(valueInArray =>
        value.some((item: any) => Object.values(item).includes(valueInArray)),
      );
    }
    return false;
  } else if ('valuesNotInArray' in parameter) {
    if (Array.isArray(value)) {
      return parameter.valuesNotInArray!.every(
        valueNotInArray =>
          !value.some((item: any) =>
            Object.values(item).includes(valueNotInArray),
          ),
      );
    }
    return true;
  }
  return false;
};

const checkCondition = (entity: any, check: Check): boolean => {
  const results = check.parameters.map(parameter =>
    checkParameter(entity, parameter),
  );
  if (check.condition === 'AND') {
    return results.every(result => result);
  } else if (check.condition === 'OR') {
    return results.some(result => result);
  }
  return false;
};

/**
 *
 * @param entity
 * @param recommendations
 * @returns
 */
export const checkRecommendations = (entity: any): Recommendation[] => {
  const validate = ajv.compile(schema);
  const valid = validate(recommendationsconfig);
  if (!valid) {
    throw new Error(`Invalid schema: ${ajv.errorsText(validate.errors)}`);
  }

  const passedRecommendations: Recommendation[] = [];

  for (const recommendation of recommendationsconfig as RecommendationSchema[]) {
    const checks = recommendation.checks.map(check =>
      checkCondition(entity, check),
    );
    if (checks.every(check => check)) {
      const { title, description, links } = recommendation;
      passedRecommendations.push({
        title,
        description,
        links,
      });
    }
  }

  return passedRecommendations;
};
