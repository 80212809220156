import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';
import { Entity, ANNOTATION_SOURCE_LOCATION } from '@backstage/catalog-model';
import { LinkButton, InfoCard } from '@backstage/core-components';

import { MissingAnnotation } from './MissingAnnotation';

export const CHANGELOG_ANNOTATION_NAME = 'changelog-name';

export const isChangelogAnnotationConfigurationOk = (
  entity: Entity,
): boolean => {
  if (
    Boolean(entity.metadata.annotations?.[CHANGELOG_ANNOTATION_NAME]) &&
    Boolean(entity.metadata.annotations?.[ANNOTATION_SOURCE_LOCATION])
  ) {
    return true;
  }
  return false;
};

export const ChangelogCardMissingAnnotation = () => {
  const { entity } = useEntity();

  return (
    <InfoCard title="Changelog" variant="fullHeight">
      <MissingAnnotation
        annotations={['changelog-name']}
        entity={entity}
        description="Add the annotation to your catalog-info.yaml as shown in the highlighted example below. If the CHANGELOG.md file is located in a different location, you must also include the path in the annotation (e.g. 'path/to/CHANGELOG.md')."
      />
      <LinkButton
        to="https://dsc.zeiss.com/catalog/default/component/digital-service-catalog/docs/plugins/"
        color="primary"
        variant="contained"
      >
        Learn more
      </LinkButton>
    </InfoCard>
  );
};
