import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  LuyEnumPicker,
  validateLuyEnumPickerValidation,
} from './LuyEnumPicker';

export const LuyEnumPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ZEISSLuyEnumPicker',
    component: LuyEnumPicker,
    validation: validateLuyEnumPickerValidation,
  }),
);
