import React from 'react';

import { ValueDisplayCard } from './ValueDisplayCard';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  DataProductEntity,
  OutputPortEntity,
} from '@zeiss/backstage-plugin-witboost-provider-common';

export const isDataProductEntity = (
  entity: any,
): entity is DataProductEntity | OutputPortEntity => {
  return entity?.spec?.type === 'dataproduct';
};

export const isOutputPortEntity = (entity: any): entity is OutputPortEntity => {
  return entity?.spec?.type === 'outputport';
};

export const DataProductDescriptorCard = () => {
  const { entity } = useEntity();

  let values = {};

  if (entity?.spec?.type === 'dataproduct') {
    const dataProduct = entity as unknown as DataProductEntity;
    values = dataProduct.spec.witboost.descriptor;
  } else if (entity?.spec?.type === 'outputport') {
    const outputPort = entity as unknown as OutputPortEntity;
    values = outputPort.spec.witboost.descriptor;
  }

  return <ValueDisplayCard title="Descriptor" values={values} />;
};
