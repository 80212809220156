import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  LuyOwnerPicker,
  validateLuyOwnerPickerValidation,
} from './LuyOwnerPicker';

export const LuyOwnerPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ZEISSLuyOwnerPicker',
    component: LuyOwnerPicker,
    validation: validateLuyOwnerPickerValidation,
  }),
);
