import React from 'react';

import { Entity, EntityLink } from '@backstage/catalog-model';
import { CodeSnippet } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { Typography, Box, makeStyles } from '@material-ui/core';

type Link = Omit<EntityLink, 'url'> & { url?: string };

type MissingAnnotationLinksType = {
  links: Link[];
  entity: Entity;
};

const useStyles = makeStyles<BackstageTheme>(theme => ({
  code: {
    borderRadius: 6,
    margin: theme.spacing(2, 0),
    background:
      theme.palette.type === 'dark' ? '#444' : theme.palette.common.white,
  },
}));

const generateYamlExampleLink = (
  links: MissingAnnotationLinksType['links'],
  entity?: Entity,
): { yamlText: string; lineNumbers: number[] } => {
  const kind = entity?.kind || 'Component';
  const name = entity?.metadata.name || 'example';
  const entitytype = entity?.spec?.type || 'website';
  const owner = entity?.spec?.owner || 'user:default/guest';

  const linkItem = (link: Link) => {
    const { url, type, title, icon } = link;

    return `
        - url: ${url ? url : 'https://www.example.com'}${
      type !== undefined ? `\n          type: ${type}` : ''
    }${title !== undefined ? `\n          title: ${title}` : ''}${
      icon !== undefined ? `\n          icon: ${icon}` : ''
    }`;
  };

  const yamlText = `apiVersion: backstage.io/v1alpha1
    kind: ${kind}
    metadata:
      name: ${name}
      links: ${links.map(link => linkItem(link))}
    spec:
      type: ${entitytype}
      owner: ${owner}`;

  let line = 6; // Line 6 is the line number that annotations are added to.
  const lineNumbers: number[] = [];

  const totalKeys = links.reduce(
    (total, obj) => total + Object.keys(obj).length + ('url' in obj ? 0 : 1),
    0,
  );

  for (let i = 0; i < totalKeys; i++) {
    lineNumbers.push(line);
    line++;
  }

  return {
    yamlText,
    lineNumbers,
  };
};

export const MissingLink = ({ links, entity }: MissingAnnotationLinksType) => {
  const classes = useStyles();

  const { yamlText, lineNumbers } = generateYamlExampleLink(links, entity);

  return (
    <>
      <Typography variant="body1">
        Add the links to your catalog-info.yaml as shown in the highlighted
        example below:
      </Typography>
      <Box className={classes.code}>
        <CodeSnippet
          text={yamlText}
          language="yaml"
          showLineNumbers
          highlightedNumbers={lineNumbers}
          customStyle={{ background: 'inherit', fontSize: '115%' }}
        />
      </Box>
    </>
  );
};
