import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

import { Config } from '@backstage/config';
import { handle as luyHandler } from './handler/luy';
import { handle as copHandler } from './handler/cop';
import type { Config as PluginConfig } from '../config';

export type RadarDynamic =
  PluginConfig['zeisstechradar']['radars']['handler'] extends infer T
    ? T extends { type: 'handler' }
      ? T
      : never
    : never;

export type RadarStatic =
  PluginConfig['zeisstechradar']['radars']['static'] extends infer T
    ? T extends { type: 'static' }
      ? T
      : never
    : never;

export class TechRadarLoader implements TechRadarApi {
  config: Config;

  constructor({ configApi }: { configApi: Config }) {
    this.config = configApi;
  }

  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const radar: RadarStatic | RadarDynamic | undefined = this.config
      .getConfig(`zeisstechradar.radars`)
      .getOptional(id);

    const backendUrl = this.config.getString('backend.baseUrl');

    switch (radar?.type) {
      case 'static': {
        const staticData = JSON.parse(radar.file) as TechRadarLoaderResponse;

        return {
          ...staticData,
          entries: staticData.entries.map(entry => ({
            ...entry,
            timeline: entry.timeline.map(timeline => ({
              ...timeline,
              date: new Date(timeline.date),
            })),
          })),
        };
      }
      case 'handler': {
        const data = await fetch(
          `${backendUrl}/api/proxy${radar.proxy}${radar.path}`,
          {
            method: 'GET',
          },
        )
          .then(async response => await response.json())
          .catch(error => {
            throw new Error(`Error while fetching data: ${error}`);
          });

        if (radar.handler === 'luy') {
          return luyHandler(data);
        }
        if (radar.handler === 'cop') {
          return copHandler(data);
        }
        throw new Error(`Handler for ${radar.handler} not implemented`);
      }
      default:
        return {
          quadrants: [],
          rings: [],
          entries: [],
        };
    }
  }
}
