import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const useIsZeissFeatureFlagged = (name: string): boolean => {
  const config = useApi(configApiRef);
  return (
    config.getOptionalBoolean(
      `zeissfeatureflags.featureflags.${name}.active`,
    ) ?? false
  );
};
