import {
  AnalyticsApi,
  AnalyticsEvent,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import { RudderAnalytics } from '@rudderstack/analytics-js';

export class RudderstackAnalytics implements AnalyticsApi {
  private rudderAnalytics;
  private readonly customUserIdTransform?: (
    userEntityRef: string,
  ) => Promise<string>;

  private constructor(
    writeKey: string,
    dataPlaneUrl: string,
    options: {
      identityApi?: IdentityApi;
      userIdTransform?:
        | 'sha-256'
        | ((userEntityRef: string) => Promise<string>);
    },
  ) {
    const { identityApi, userIdTransform = 'sha-256' } = options;
    this.rudderAnalytics = new RudderAnalytics();
    this.rudderAnalytics.load(writeKey, dataPlaneUrl, {});

    this.customUserIdTransform =
      typeof userIdTransform === 'function' ? userIdTransform : undefined;

    if (identityApi) {
      this.setUserFrom(identityApi).then(() => {
        return;
      });
    }
  }

  static fromConfig(
    config: Config,
    options: {
      identityApi?: IdentityApi;
      userIdTransform?:
        | 'sha-256'
        | ((userEntityRef: string) => Promise<string>);
    } = {},
  ) {
    const writeKey = config.getString('analytics.rudderstack.writeKey');
    const dataPlaneUrl = config.getString('analytics.rudderstack.dataPlaneUrl');
    return new RudderstackAnalytics(writeKey, dataPlaneUrl, options);
  }

  captureEvent(event: AnalyticsEvent) {
    const { action, ...rest } = event;
    this.rudderAnalytics.track(action, rest);
  }

  private async setUserFrom(identityApi: IdentityApi) {
    const { userEntityRef } = await identityApi.getBackstageIdentity();
    const userId = await this.getPrivateUserId(userEntityRef);
    this.rudderAnalytics.identify(userId);
  }

  private getPrivateUserId(userEntityRef: string): Promise<string> {
    if (this.customUserIdTransform) {
      return this.customUserIdTransform(userEntityRef);
    }
    return this.hash(userEntityRef);
  }

  private async hash(value: string): Promise<string> {
    const digest = await window.crypto.subtle.digest(
      'sha-256',
      new TextEncoder().encode(value),
    );
    const hashArray = Array.from(new Uint8Array(digest));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
}
