import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  MultiEntityPicker,
  validateMultiEntityPickerValidation,
} from './MultiEntityPicker';

export const MultiEntityPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ZEISSMultiEntityPicker',
    component: MultiEntityPicker,
    validation: validateMultiEntityPickerValidation,
  }),
);
