import { createPlugin } from '@backstage/core-plugin-api';
import { createSearchResultListItemExtension } from '@backstage/plugin-search-react';

const plugin = createPlugin({ id: 'storybook-search' });

export const StorybookSearchResultListItem = plugin.provide(
  createSearchResultListItemExtension({
    name: 'StorybookSearchResultListItem',
    component: () =>
      import('./components/StorybookSearchResultListItem').then(
        m => m.StorybookSearchResultListItem,
      ),
    predicate: result => result.type === 'storybook-search',
  }),
);
