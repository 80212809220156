import { TechRadarLoaderResponse } from '@backstage/plugin-tech-radar';

interface LuyRadarSegment {
  name: string;
  index: number;
  color: string;
}

interface LuyRadarCategory {
  name: string;
  index: number;
}

interface LuyRadarRing {
  name: string;
  index: number;
  color: string;
}

interface LuyRadarEntry {
  id: number;
  name: string;
  label: string;
  description: string;
  segment: string;
  categorie: string;
  maturity: string;
  serviceType: string;
  contacts: string;
  tags?: string;
  lastChange?: string;
  hasSuccessor: boolean;
  ring: {
    number: number;
  };
  usedCount: number;
  usedSize: number;
  link: string;
}

// derived from luy api
interface LuyRadarResponse {
  radarCategories: LuyRadarCategory[];
  radarRings: LuyRadarRing[];
  radarEntries: LuyRadarEntry[];
  radarSegments: LuyRadarSegment[];
}

const mergeSegmentNames = (name: string) => {
  if (name === 'Integration' || name === 'IaaS/CaaS & Network') {
    return 'IaaS/CaaS, Network & Integration';
  }

  return name;
};

export const handle = (data: LuyRadarResponse): TechRadarLoaderResponse => {
  const quadrants = data.radarSegments.reduce(
    (accumulator: TechRadarLoaderResponse['quadrants'], segment) => {
      if (
        segment.name !== 'Device' &&
        !accumulator.find(
          quadrant => quadrant.id === mergeSegmentNames(segment.name),
        )
      ) {
        accumulator.push({
          id: mergeSegmentNames(segment.name),
          name: mergeSegmentNames(segment.name),
        });
      }
      return accumulator;
    },
    [],
  );

  const rings = data.radarRings.map(ring => {
    return {
      id: ring.name,
      name: ring.name,
      color: ring.color,
    };
  });

  const entries = data.radarEntries
    .filter(entity => entity.segment !== 'Device')
    .map(entity => {
      const description = `${entity.description}
      \n\nService Type: ${entity.serviceType ?? ''}
        \n\nContacts: ${entity.contacts ?? ''}
        \n\nTags: ${entity.tags ?? ''}`;

      return {
        timeline: [
          {
            ringId: entity.maturity,
            date: entity.lastChange
              ? new Date(Date.parse(entity.lastChange))
              : new Date(),
            description: 'Was added to Techradar.',
          },
        ],
        url: entity.link,
        key: entity.label.toLowerCase(),
        id: entity.id.toString(),
        title: entity.name,
        quadrant: mergeSegmentNames(entity.segment),
        description: description,
      };
    });

  return {
    quadrants: quadrants,
    rings: rings,
    entries: entries,
  };
};
