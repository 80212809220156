import { useEffect } from 'react';
import {
  useApi,
  configApiRef,
  featureFlagsApiRef,
} from '@backstage/core-plugin-api';

type ZeissFeatureFlags = {
  [name: string]: {
    active: boolean;
    description?: string;
  };
};

/**
 * A hook that imports configured ZEISS feature flags into the Backstage feature flag system.
 *
 * @public
 */
export function useZeissFeatureFlags() {
  const configApi = useApi(configApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  useEffect(() => {
    const featureFlags: ZeissFeatureFlags | undefined = configApi.getOptional(
      'zeissfeatureflags.featureflags',
    );

    if (featureFlags) {
      for (const name of Object.keys(featureFlags)) {
        const { active, description } = featureFlags[name];
        if (active === true) {
          featureFlagsApi.registerFlag({
            name: name,
            pluginId: '',
            description: description,
          });
        }
      }
    }
  }, [configApi, featureFlagsApi]);
}
