import {
  type EntityFilterQuery,
  CATALOG_FILTER_EXISTS,
} from '@backstage/catalog-client';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { FormControl, Grid, TextField } from '@material-ui/core';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FieldValidation } from '@rjsf/utils';
import {
  MultiEntityPickerFilterQueryValue,
  MultiEntityPickerProps,
  MultiEntityPickerUiOptions,
  MultiEntityPickerFilterQuery,
} from './schema';
import { useUserProfile } from '@backstage/plugin-user-settings';

export const MultiEntityPicker = (props: MultiEntityPickerProps) => {
  const { onChange, required, uiSchema } = props;
  const defaultKind = uiSchema['ui:options']?.defaultKind;
  const defaultNamespace =
    uiSchema['ui:options']?.defaultNamespace || undefined;

  const kinds = ['Component', 'API', 'System'];

  const [kindFilter, setKindFilter] = useState<string | undefined>(undefined);
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [entities, setEntities] = useState<any[]>([]);

  const { backstageIdentity } = useUserProfile();
  const userEntityRefs = backstageIdentity?.ownershipEntityRefs;

  let cF = uiSchema['ui:options']?.catalogFilter;

  if (Array.isArray(cF)) {
    // If catalogFilter is an array, spread it
    cF = [...cF];
  } else if (typeof cF === 'object') {
    // If catalogFilter is an object, put it in an array
    cF = [cF];
  } else {
    // If catalogFilter is neither an array nor an object, default to an empty array
    cF = [];
  }

  const catalogFilter = buildCatalogFilter({
    'ui:options': {
      catalogFilter: [
        ...cF,
        {
          kind: kinds,
          'spec.owner': userEntityRefs ?? '',
        },
      ],
    },
  });
  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    if (userEntityRefs) {
      const fetchEntities = async () => {
        const { items } = await catalogApi.getEntities(
          catalogFilter ? { filter: catalogFilter } : undefined,
        );
        setEntities(items);
      };

      fetchEntities();
    }
  }, [userEntityRefs, catalogApi, catalogFilter]);

  const handleSelect = (entityRef: string) => {
    setSelectedEntities(prevSelectedEntities =>
      prevSelectedEntities.includes(entityRef)
        ? prevSelectedEntities.filter(ref => ref !== entityRef)
        : [...prevSelectedEntities, entityRef],
    );
    onChange(
      selectedEntities.includes(entityRef)
        ? selectedEntities.filter(ref => ref !== entityRef)
        : [...selectedEntities, entityRef],
    );
  };

  const handleSelectAll = () => {
    setSelectedEntities(
      entities
        ?.filter(e => !kindFilter || e.kind === kindFilter)
        .map(stringifyEntityRef) || [],
    );
    onChange(
      entities
        ?.filter(e => !kindFilter || e.kind === kindFilter)
        .map(stringifyEntityRef) || [],
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (entities?.length === 1) {
      onChange([stringifyEntityRef(entities[0])]);
    }
  }, [entities, onChange]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={5}>
          <FormControl margin="normal" required={required} fullWidth>
            <InputLabel id="kind-filter-label">Kind Filter</InputLabel>
            <Select
              labelId="kind-filter-label"
              id="kind-filter"
              value={kindFilter || undefined}
              onChange={event => setKindFilter(event.target.value as string)}
            >
              <MenuItem value={undefined}>
                <em>None</em>
              </MenuItem>
              {kinds.map(kind => (
                <MenuItem key={kind} value={kind}>
                  {kind}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            margin="normal"
            label="Filter by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            color="default"
            size="large"
            onClick={handleSelectAll}
            disabled={!entities?.length}
          >
            Select All
          </Button>
        </Grid>
      </Grid>
      <FormGroup>
        <Grid container spacing={3}>
          {entities
            ?.filter(
              e =>
                (!kindFilter || e.kind === kindFilter) &&
                (!searchTerm || e.metadata.name.includes(searchTerm)),
            )
            .map(e => {
              const entityRef = stringifyEntityRef(e);
              return (
                <Grid item xs={12} sm={4} key={entityRef}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedEntities.includes(entityRef)}
                        onChange={() => handleSelect(entityRef)}
                        name={entityRef}
                      />
                    }
                    label={humanizeEntityRef(e, {
                      defaultKind,
                      defaultNamespace,
                    })}
                  />
                </Grid>
              );
            })}
        </Grid>
      </FormGroup>
    </div>
  );
};

export const validateMultiEntityPickerValidation = (
  values: string[],
  validation: FieldValidation,
) => {
  values.forEach(value => {
    try {
      parseEntityRef(value);
    } catch {
      validation.addError(`${value} is not a valid entity ref`);
    }
  });
};

/**
 * Converts a special `{exists: true}` value to the `CATALOG_FILTER_EXISTS` symbol.
 *
 * @param value - The value to convert.
 * @returns The converted value.
 */
function convertOpsValues(
  value: Exclude<MultiEntityPickerFilterQueryValue, Array<any>>,
): string | symbol {
  if (typeof value === 'object' && value.exists) {
    return CATALOG_FILTER_EXISTS;
  }
  return value?.toString();
}

/**
 * Converts schema filters to entity filter query, replacing `{exists:true}` values
 * with the constant `CATALOG_FILTER_EXISTS`.
 *
 * @param schemaFilters - An object containing schema filters with keys as filter names
 * and values as filter values.
 * @returns An object with the same keys as the input object, but with `{exists:true}` values
 * transformed to `CATALOG_FILTER_EXISTS` symbol.
 */
function convertSchemaFiltersToQuery(
  schemaFilters: MultiEntityPickerFilterQuery,
): Exclude<EntityFilterQuery, Array<any>> {
  const query: EntityFilterQuery = {};

  for (const [key, value] of Object.entries(schemaFilters)) {
    if (Array.isArray(value)) {
      query[key] = value;
    } else {
      query[key] = convertOpsValues(value);
    }
  }

  return query;
}

/**
 * Builds an `EntityFilterQuery` based on the `uiSchema` passed in.
 * If `catalogFilter` is specified in the `uiSchema`, it is converted to a `EntityFilterQuery`.
 *
 * @param uiSchema The `uiSchema` of an `EntityPicker` component.
 * @returns An `EntityFilterQuery` based on the `uiSchema`, or `undefined` if `catalogFilter` is not specified in the `uiSchema`.
 */
function buildCatalogFilter(
  uiSchema: MultiEntityPickerProps['uiSchema'],
): EntityFilterQuery | undefined {
  const catalogFilter: MultiEntityPickerUiOptions['catalogFilter'] | undefined =
    uiSchema['ui:options']?.catalogFilter;

  if (!catalogFilter) {
    return undefined;
  }

  if (Array.isArray(catalogFilter)) {
    return catalogFilter.map(convertSchemaFiltersToQuery);
  }

  return convertSchemaFiltersToQuery(catalogFilter);
}
