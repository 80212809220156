import React, { ReactNode } from 'react';

import {
  Content,
  ContentHeader,
  CreateButton,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import {
  createExternalRouteRef,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityProcessingStatusPicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListFilterKind,
  UserListPicker,
  EntityKindPicker,
  EntityNamespacePicker,
  EntityOwnerPickerProps,
} from '@backstage/plugin-catalog-react';
import { CatalogTable } from './CatalogTable';

import { SecurityClassificationFilter } from './SecurityClassificationFilter';
import { CatalogTableRow, CatalogTableColumnsFunc } from './types';

const createComponentRouteRef = createExternalRouteRef({
  id: 'create-component',
  optional: true,
});

type BaseCatalogPageProps = {
  filters: ReactNode;
  content?: ReactNode;
  pagination?: boolean | { limit?: number };
};

function BaseCatalogPage(props: BaseCatalogPageProps) {
  const { filters, content = <CatalogTable />, pagination } = props;
  const createComponentLink = useRouteRef(createComponentRouteRef);

  return (
    <PageWithHeader title="ZEISS Digital Service Catalog" themeId="home">
      <Content>
        <ContentHeader title="">
          <CreateButton
            title="CREATE"
            to={createComponentLink && createComponentLink()}
          />
          <SupportButton>All your software catalog entities</SupportButton>
        </ContentHeader>
        {/* @ts-ignore */}
        <EntityListProvider pagination={pagination}>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>{filters}</CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>{content}</CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}

interface CustomCatalogPageProps {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[] | CatalogTableColumnsFunc;
  actions?: TableProps<CatalogTableRow>['actions'];
  initialKind?: string;
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  ownerPickerMode?: EntityOwnerPickerProps['mode'];
  pagination?: boolean | { limit?: number };
}

export function CustomCatalogPage(props: CustomCatalogPageProps) {
  const {
    columns,
    actions,
    initiallySelectedFilter = 'owned',
    initialKind = 'component',
    tableOptions = {},
    emptyContent,
    pagination,
    ownerPickerMode,
  } = props;

  return (
    <BaseCatalogPage
      filters={
        <>
          <EntityKindPicker initialFilter={initialKind} />
          <EntityNamespacePicker />
          <EntityTypePicker />
          <UserListPicker initialFilter={initiallySelectedFilter} />
          <EntityOwnerPicker mode={ownerPickerMode} />
          <EntityLifecyclePicker />
          <EntityTagPicker />
          <SecurityClassificationFilter />
          <EntityProcessingStatusPicker />
        </>
      }
      content={
        <CatalogTable
          columns={columns}
          actions={actions}
          tableOptions={tableOptions}
          emptyContent={emptyContent}
        />
      }
      pagination={pagination}
    />
  );
}
