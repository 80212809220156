import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FeatureFlagged } from '@backstage/core-app-api';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  EntitySwitchCaseProps,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import { MissingAnnotationEmptyState } from '@backstage/plugin-catalog-react';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import {
  EntityAzurePipelinesContent,
  EntityAzurePullRequestsContent,
  EntityAzureGitTagsContent,
  isAzureDevOpsAvailable,
} from '@backstage/plugin-azure-devops';
import {
  isAlertSelectorAvailable,
  isDashboardSelectorAvailable,
  EntityGrafanaAlertsCard,
  EntityGrafanaDashboardsCard,
} from '@k-phoen/backstage-plugin-grafana';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';
import {
  AdrReader,
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage/plugin-adr';
import { adrImageRewriteDecorator } from './adr-decorators/imageRewrite';
import {
  BlackDuckCard,
  RiskCard,
  isBlackDuckAvailable,
} from 'backstage-plugin-blackduck';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import {
  SecurityAssetClassification,
  isSecurityAssetClassificationAvailable,
} from './components/SecurityAssetClassification';
import {
  EntitySoundcheckContent,
  GroupSoundcheckContent,
} from '@spotify/backstage-plugin-soundcheck';
import {
  EntityApiDocsSpectralLinterContent,
  isApiDocsSpectralLinterAvailable,
} from '@dweber019/backstage-plugin-api-docs-spectral-linter';
import {
  ObservabilityTelemetryCard,
  isObservabilityTelemetryAvailable,
} from './components/ObservabilityTelemetryCard';
import { FilteredEntityLinksCard } from './components/FilteredEntityLinksCard';
import { QetaContent } from './components/QetaContent';
import { isChangelogAnnotationConfigurationOk } from './components/ChangelogCard';
import { EntityChangelogCard } from '@rsc-labs/backstage-changelog-plugin';
import {
  RecommendationsCard,
  isRecommendationAvailable,
} from '@zeiss/backstage-plugin-recommendations';
import {
  LibraryCheckPage,
  useIsProjectLibrariesAvailable,
} from '@anakz/backstage-plugin-library-check';

import { RequirePermission } from '@backstage/plugin-permission-react';
import { qetaReadPermission } from '@drodil/backstage-plugin-qeta-common';
import { Entity } from '@zeiss/backstage-plugin-security-asset-classification-common';
import { useIsZeissFeatureFlagged } from '@zeiss/backstage-plugin-feature-flags';
import {
  DataProductDescriptorCard,
  isDataProductEntity,
  isOutputPortEntity,
} from './components/DataCatalogCards';

const isString = (value: any): boolean =>
  typeof value === 'string' || value instanceof String;

const SingleEntitySwitch = ({
  if: condition,
  children,
}: EntitySwitchCaseProps) => (
  <EntitySwitch>
    <EntitySwitch.Case if={condition}>{children}</EntitySwitch.Case>
  </EntitySwitch>
);

const qetaContent = (
  <EntityLayout.Route path="/qeta" title="Q&A">
    <RequirePermission permission={qetaReadPermission}>
      <QetaContent />
    </RequirePermission>
  </EntityLayout.Route>
);

const securityAssetClassification = (
  <SingleEntitySwitch
    if={e => isSecurityAssetClassificationAvailable(e as Entity)}
  >
    <Grid item md={6} xs={12}>
      <SecurityAssetClassification />
    </Grid>
  </SingleEntitySwitch>
);

const observabilityTelemetry = (
  <SingleEntitySwitch if={isObservabilityTelemetryAvailable}>
    <Grid item md={6} xs={12}>
      <ObservabilityTelemetryCard />
    </Grid>
  </SingleEntitySwitch>
);

const changelog = (
  <SingleEntitySwitch if={isChangelogAnnotationConfigurationOk}>
    <Grid item md={6} xs={12}>
      <EntityChangelogCard />
    </Grid>
  </SingleEntitySwitch>
);

const soundcheckContent = (
  <EntityLayout.Route path="/soundcheck" title="Soundcheck">
    <EntitySoundcheckContent />
  </EntityLayout.Route>
);

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <ExpandableNavigation />
      <LightBox />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isAzureDevOpsAvailable}>
      <EntityAzurePipelinesContent defaultLimit={25} />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const securityContent = (
  <EntityLayout.Route path="/security" title="Security">
    <EntitySwitch>
      <EntitySwitch.Case if={isBlackDuckAvailable || isSonarQubeAvailable}>
        <Grid container spacing={3} alignItems="stretch">
          <SingleEntitySwitch if={isBlackDuckAvailable}>
            <Grid item xs={12} md={6}>
              <RiskCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <BlackDuckCard />
            </Grid>
          </SingleEntitySwitch>
          <SingleEntitySwitch if={isSonarQubeAvailable}>
            <Grid item xs={12} md={6}>
              <EntitySonarQubeCard variant="gridItem" />
            </Grid>
          </SingleEntitySwitch>
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <MissingAnnotationEmptyState
          annotation={['blackduck/project', 'sonarqube.org/project-key']}
          readMoreUrl="https://dsc.zeiss.com/catalog/default/component/digital-service-catalog/docs/plugins/"
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  </EntityLayout.Route>
);

const observabilityContent = (
  <EntityLayout.Route path="/observability" title="Observability">
    <EntitySwitch>
      <EntitySwitch.Case
        if={
          isArgocdAvailable ||
          isAlertSelectorAvailable ||
          isString(isDashboardSelectorAvailable)
        }
      >
        <Grid container spacing={3} alignItems="stretch">
          <SingleEntitySwitch if={isAlertSelectorAvailable}>
            <Grid item xs={12} md={6}>
              <EntityGrafanaAlertsCard />
            </Grid>
          </SingleEntitySwitch>
          <SingleEntitySwitch
            if={entity => isString(isDashboardSelectorAvailable(entity))}
          >
            <Grid item xs={12} md={6}>
              <EntityGrafanaDashboardsCard />
            </Grid>
          </SingleEntitySwitch>
          <SingleEntitySwitch if={isArgocdAvailable}>
            <Grid item xs={12} md={6}>
              <EntityArgoCDOverviewCard />
            </Grid>
          </SingleEntitySwitch>
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <MissingAnnotationEmptyState
          annotation={[
            'grafana/dashboard-selector',
            'grafana/alert-label-selector',
            'argocd/app-name',
          ]}
          readMoreUrl="https://dsc.zeiss.com/catalog/default/component/digital-service-catalog/docs/plugins/"
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  </EntityLayout.Route>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const entityRecommendationsContent = (
  <SingleEntitySwitch if={isRecommendationAvailable}>
    <Grid item xs={12}>
      <RecommendationsCard />
    </Grid>
  </SingleEntitySwitch>
);

const adrContent = (
  <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
    <EntityAdrContent
      contentDecorators={[
        AdrReader.decorators.createRewriteRelativeLinksDecorator(),
        AdrReader.decorators.createRewriteRelativeEmbedsDecorator(),
        AdrReader.decorators.createFrontMatterFormatterDecorator(),
        adrImageRewriteDecorator,
      ]}
    />
  </EntityLayout.Route>
);

const libraryCheckContent = (
  <EntityLayout.Route
    if={_ =>
      useIsZeissFeatureFlagged('library-check') &&
      useIsProjectLibrariesAvailable()
    }
    path="/library-check"
    title="Library Check"
  >
    <LibraryCheckPage />
  </EntityLayout.Route>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    {entityRecommendationsContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    {observabilityTelemetry}

    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    {securityAssetClassification}

    {changelog}

    <Grid item md={6} xs={12}>
      <FilteredEntityLinksCard
        removeByType={[
          'dashboard-workspace',
          'tracing-workspace',
          'logging-workspace',
          'incident-playbooks',
          'tyk-api-workspace',
          'incident-contact-mail',
          'itsm-ticket-create',
        ]}
      />
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}

    {observabilityContent}

    {securityContent}

    {soundcheckContent}

    {libraryCheckContent}

    <EntityLayout.Route path="/dependencies" title="Dependencies & API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isAzureDevOpsAvailable}
      path="/pull-requests"
      title="Pull Requests & Git Tags"
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <EntityAzurePullRequestsContent defaultLimit={25} />
        </Grid>
        <Grid item md={12}>
          <EntityAzureGitTagsContent />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {adrContent}
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}

    {observabilityContent}

    {securityContent}

    {soundcheckContent}

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultComponentEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}

    {soundcheckContent}

    {adrContent}
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultComponentEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        {securityAssetClassification}
        <Grid item md={6} xs={12}>
          <FilteredEntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {qetaContent}

    <FeatureFlagged with="api-spectral-linter">
      <EntityLayout.Route
        if={isApiDocsSpectralLinterAvailable}
        path="/linter"
        title="Linter"
      >
        <EntityApiDocsSpectralLinterContent />
      </EntityLayout.Route>
    </FeatureFlagged>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/soundcheck" title="Soundcheck">
      <GroupSoundcheckContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        {securityAssetClassification}
        <Grid item md={6} xs={12}>
          <FilteredEntityLinksCard />
        </Grid>
        <Grid item md={6}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}

    {observabilityContent}

    {securityContent}

    {soundcheckContent}

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>

    {adrContent}
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}

    {adrContent}
  </EntityLayout>
);

const templatePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {qetaContent}
  </EntityLayout>
);

const locationPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        {entityRecommendationsContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>

        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>

        <Grid item md={6} xs={12}>
          <FilteredEntityLinksCard
            removeByType={[
              'dashboard-workspace',
              'tracing-workspace',
              'logging-workspace',
              'incident-playbooks',
              'tyk-api-workspace',
              'incident-contact-mail',
              'itsm-ticket-create',
            ]}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {qetaContent}
  </EntityLayout>
);

export const dataCatalogPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <DataProductDescriptorCard />
        </Grid>
        <SingleEntitySwitch if={isDataProductEntity}>
          <Grid item md={6}>
            <EntityHasComponentsCard variant="gridItem" />
          </Grid>
        </SingleEntitySwitch>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isDataProductEntity} children={dataCatalogPage} />
    <EntitySwitch.Case if={isOutputPortEntity} children={dataCatalogPage} />
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('template')} children={templatePage} />
    <EntitySwitch.Case if={isKind('location')} children={locationPage} />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
